<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvExhibtionsList></OvExhibtionsList>

	2020-11-23	init

-->

<template>
	<div class="OvExhibtionsList contentModule" :class="elmClasses">
		<div class="OvExhibtionsList__inner contentModule__inner hSpace hSpace--app maxWidth maxWidth--app">
			<div class="OvExhibtionsList__group" v-for="(group, index) in exhibitionGroups" :key="index">
				<div class="OvExhibtionsList__groupLeft lineCrop font font--bold font--sizeHuge">
					{{group.key}}
				</div>
				<div class="OvExhibtionsList__groupRight">
					<router-link
						:to="{ name: 'OvExhibitionView', params: { slug : post.slug } }"
						class="OvExhibtionsList__exhibition hover hover--listItem"
						v-for="(post, index2) in group.items" :key="index2">
						<span
							class="OvExhibtionsList__exhibitionTitle lineCrop font font--bold"
							:class="{'font--sizeMedium' : ['xl', 'lg', 'dt'].includes($mq)}"
							v-html="app.getPostTitle( post )"
						></span>
						<!--
						<div>
							<OvLabel2
								class="font OvLabel2--isArrow"
								:class="{'font--sizeMedium' : ['xl', 'lg', 'dt'].includes($mq)}"
								:showArrowAfter="true"
								bgColor="white"
							></OvLabel2>
						</div>
						-->
						<span class="linkArrow font font--sizeMedium">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.08 21">
							  <path id="Pfad_64" data-name="Pfad 64" d="M2.55-11.433H21.3L15-17.862l2.34-2.418L27.63-9.78,17.34.72,15-1.7l6.3-6.429H2.55Z" transform="translate(-2.55 20.28)" fill="rgba(0,0,0,0.97)"/>
							</svg>
						</span>
					</router-link>
				</div>
			</div>
		</div>
		<!--
		<pre>{{exhibitionGroups}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import OvLabel2 from '@/components/OvLabel2.vue'

	export default {
		name: 'OvExhibtionsList',
		components: {
			OvLabel2,
		},
		mixins: [
			RestHandler,
		],
		props: {},
		data() {
			return {
				posts : null,
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			exhibitionGroups(){
				const posts    = this.posts
				let years      = []
				let yearGroups = []

				/*
				console.group('OvExhibtionsList • exhibitionGroups')
				console.log();
				*/

				if( posts ){
					posts.forEach( (post)=>{
						const pageTitle       = post.acf.pageTitle ? post.acf.pageTitle : post.title.rendered
						const responsibleDate = post.acf.startDate
						const dateParts       = responsibleDate.split('-')
						const year            = dateParts[0]
						const month           = dateParts[1]
						const day             = dateParts[2]
						let newLength         = null

						if( !years.includes( year ) ){
							years.push( year )
							yearGroups.push({
								key   : year,
								items : [],
							})
						}

						this._.last( yearGroups ).items.push( post )
					})
				}

				//yearGroups = yearGroups.reverse()
				yearGroups = this._.sortBy( yearGroups, 'key' )
				yearGroups = yearGroups.reverse()

				//console.log('yearGroups:', yearGroups)
				//console.groupEnd()

				//this._.reverse( yearGroups )

				return yearGroups
			},
		},
		methods: {
			fetchExhibitions(){
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/exhibitions',
					params : {
						postType : 'ausstellungen_post',
						range    : 'past',
						category : 1, // hauptausstellungen only
						perPage  : 99,
						todayOnlyCache : this.$root.getCurrentDateAsYYYYMMDD(),
					},
					callback : (response) => {
						let results = response.data.result
						results = this._.sortBy( results, [function(o) { return o.acf.endDate }] )
						results = this._.reverse( results )

						this.posts = results

						//console.log('response:', response )
					},
				})
			},
		},
		created() {},
		mounted() {
			// fetch exhibitions
			this.fetchExhibitions()
		},
		destroyed () {},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvExhibtionsList { // layout
		&__group {
			.grid;
			.grid--colGap;
			.grid--setAreas;

			@media @mq[dt] { .grid--cols-12; }
			@media @mq[md] { .grid--cols-8; }
			@media @mq[sm] { .grid--cols-8;  }

			grid-template-areas: "a a a b b b b b b b b b";

			@media @mq[md] {
				grid-template-areas: "a a a a a a a a"
									 "b b b b b b b b";
			}
		}
	}
	.OvExhibtionsList { // styling
		&__group {
			border-top: 3px solid black;
		}
		&__groupLeft {
			padding-top: 0.2em;

			@media @mq[md] {
				padding-top: 0.6em;
				padding-bottom: 0.6em;
				padding-left: 0.185em;
			}
		}
		&__exhibition {
			padding-top: 0.5em;
			padding-bottom: 0.3em;
			padding-left: 0.25rem;
			padding-right: 0.5rem;

			display: flex;
			justify-content: space-between;
			color: inherit;

			& + & { border-top: 3px solid black; }
			.OvExhibtionsList__group:last-child &:last-child { border-bottom: 3px solid black; }

			&Title {
				flex-grow: 1;
				padding-right: 2em;
			}

			.OvLabel2 {
				@media @mq[md] {
					transform: translateY(-10%);
				}
			}

			@media @mq[dt] {
				min-height: 4em;
			}
			@media @mq[md] {
				//min-height: 3.35em;
				border-top: 3px solid black
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
