<template>
	<MhRouterView class="OvDevView view background2--color-primary-background" :isContentReady="isContentReady">

		<div class="minHeight minHeight--100 vSpace vSpace--viewContent">

			<OvMainHeadline
				:text="'Dev'"
			></OvMainHeadline>

			<div>mario</div>
			<a href="#" data-token="0c0805956938d8b6731ee090d5bf7199" onclick="mjOpenPopin(event, this)">Jetzt abonnieren!</a>

			<OvSpacer type="default" :rows="6"></OvSpacer>
			<OvEventsList
				:title="'Alle Veranstaltungen'"
				:layout="'calendar'"
				:exposeIds="_.map(app.runningMainExhibitions, 'id')"
			></OvEventsList>
			<hr/>


			<OvSpacer type="default" :rows="6"></OvSpacer>
			<div class="contentModule__inner font font--sizeSmall maxWidth maxWidth--app hSpace hSpace--app">
				Und hier mal noch etwas Text davorM
				<OvLabel2 :text="'Schleuse'"></OvLabel2>
				M
			</div>
			<div class="contentModule__inner font font--sizeDefault maxWidth maxWidth--app hSpace hSpace--app">
				Und hier mal noch etwas Text davorM
				<OvLabel2 :text="'Schleuse'"></OvLabel2>
				M
			</div>
			<div class="contentModule__inner font font--sizeMedium maxWidth maxWidth--app hSpace hSpace--app">
				Und hier mal noch etwas Text davorM
				<OvLabel2 :text="'Schleuse'"></OvLabel2>
				M
			</div>
			<div class="contentModule__inner font font--sizeBig maxWidth maxWidth--app hSpace hSpace--app">
				Und hier mal noch etwas Text davorM
				<OvLabel2 :text="'Schleuse'"></OvLabel2>
				M
			</div>

			<OvSpacer type="default" :rows="6"></OvSpacer>

			<div class="contentModule__inner font XXXfont--sizeBig maxWidth maxWidth--app hSpace hSpace--app">
				font--sizeDefault M
				<OvLabel2 text="Schleuse"></OvLabel2> &nbsp;
				<OvLabel2 text="Labor"></OvLabel2> &nbsp;
				<OvLabel2 text="Alle Veranstaltungen"></OvLabel2> &nbsp;
				<OvLabel2 text="Weißes Label" bgColor="white" textColor="black"></OvLabel2> &nbsp;
				M
				<OvLabel2 text="Alle Veranstaltungen" bgColor="white" textColor="black" :showArrowAfter="true"></OvLabel2> &nbsp;
				<OvLabel2 bgColor="white" textColor="black" :showArrowAfter="true"></OvLabel2> &nbsp;
				<OvLabel2 bgColor="white" textColor="black"></OvLabel2> &nbsp;
				M
			</div>
			<OvSpacer type="default" :rows="1"></OvSpacer>
			<div class="contentModule__inner font font--sizeSmall maxWidth maxWidth--app hSpace hSpace--app">
				font--sizeSmall M
				<OvLabel2 text="Schleuse"></OvLabel2> &nbsp;
				<OvLabel2 text="Labor"></OvLabel2> &nbsp;
				<OvLabel2 text="Schleuse + Labor"></OvLabel2> &nbsp;
				<OvLabel2 bgColor="white" textColor="black" :showArrowAfter="true"></OvLabel2> &nbsp;
			</div>

			<OvSpacer type="default" :rows="6"></OvSpacer>

			<OvEventsList
				:title="'Alle Veranstaltungen'"
				:layout="'calendar'"
				:exposeIds="_.map(app.runningMainExhibitions, 'id')"
			></OvEventsList>
			<hr/>
			<OvEventsList
				:title="'Schleuse Veranstaltungen'"
				:layout="'calendar'"
				:filter="{ category : 5 }"
				:exposeIds="_.map(app.runningMainExhibitions, 'id')"
			></OvEventsList>
			<hr/>
			<OvEventsList
				:title="'Labor Veranstaltungen'"
				:layout="'calendar'"
				:filter="{ category : 4 }"
				:exposeIds="_.map(app.runningMainExhibitions, 'id')"
			></OvEventsList>
			<hr/>
			<OvEventsList
				:title="'Gehört zu \'Eine aktuelle Hauptausstellung\' Veranstaltungen'"
				:layout="'calendar'"
				:filter="{ category : 'belongsTo', belongsToId : 183 }"
				:exposeIds="_.map(app.runningMainExhibitions, 'id')"
			></OvEventsList>
			<hr/>
			<OvEventsList
				:title="'Alle Veranstaltungen'"
				:layout="'innerModule'"
				:exposeIds="_.map(app.runningMainExhibitions, 'id')"
			></OvEventsList>
			<hr/>

			<OvSpacer type="default" :rows="6"></OvSpacer>
			<OvExhibtionsList></OvExhibtionsList>

		</div>
		<OvMainFooter></OvMainFooter>

		<!--
		<pre data-name="page">{{page}}</pre>
		-->

	</MhRouterView>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js' // TODO: remove this
	import RestHandler from '@/components/RestHandler/RestHandler.js'

	import MhRouterView from '@/components/MhRouterView/MhRouterView.vue'
	import OvMainHeader from '@/components/OvMainHeader.vue'
	import OvMainHeadline from '@/components/OvMainHeadline.vue'
	import OvExhibtionsList from '@/components/OvExhibtionsList.vue'
	import OvMainFooter from '@/components/OvMainFooter.vue'
	import OvSpacer from '@/components/OvSpacer.vue'
	import OvEventsList from '@/components/OvEventsList.vue'
	import OvLabel2 from '@/components/OvLabel2.vue'

	export default {
		name: 'OvDevView',
		components: {
			MhRouterView,
			OvMainHeader,
			OvMainHeadline,
			OvExhibtionsList,
			OvMainFooter,
			OvSpacer,
			OvEventsList,
			OvLabel2,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {},
		data() {
			return {
				page : {},
				isContentReady : false,
			}
		},
		watch: {
			restHandler__pendingRequests: {
				handler( to, from, doLog = false ) {
					const icon = to ? '❌' : '✅'

					if( doLog ){
						console.groupCollapsed(this.$options.name, '• watch restHandler__pendingRequests', from, to, icon)
						console.groupEnd()
					}

					if( !to ) this.isContentReady = true
				},
				immediate : true,
			},
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			mainTitle() {
				const pageTitle    = this.$root.nl2br( this._.trim( this._.get(this.page, 'title.rendered') ) )
				const acfPageTitle = this.$root.nl2br( this._.trim( this._.get(this.page, 'acf.pageTitle') ) )

				return acfPageTitle ? acfPageTitle : pageTitle
			},
		},
		methods: {},
		created(){},
		mounted() {
			// fetch page
			this.restHandler__fetch({
				action : 'GET',
				route : '/wp-json/mh/v1/posts',
				params : {
					slug : this.$route.params.slug
				},
				callback : (response) => {
					this.page = response.data.result[0]

					//console.log('response:', response )
				},
			})
		},
		beforeDestroy(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";

	.OvDevView {
		//background-color: yellow;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
